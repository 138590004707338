import React from "react";

import GalleryThumbnails from "src/components/gallery/gallery-thumbnails";
import btnToggleGrid from "src/images/btn-gallery-grid.png";

/**
 * Gallery: custom footer for react-images. 
 * This is used instead of the default react-images <Footer /> component.
 * See http://jossmac.github.io/react-images/#/components for more info on custom components.
 */
class GalleryCustomFooter extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleThumbnails = this.handleToggleThumbnails.bind(this);
    this.handleThumbnailSelected = this.handleThumbnailSelected.bind(this);
  }

  state = {
    galleryThumbnailsIsOpen: false
  };

  // Show and hide the thumbs grid (<GalleryThumbnails />)
  handleToggleThumbnails() {
    this.setState({ galleryThumbnailsIsOpen: !this.state.galleryThumbnailsIsOpen })
  }

  // Switch carousel to thumb selected
  handleThumbnailSelected(selectedIndex) {
    // close thumbs grid
    // console.log(selectedIndex);
    // console.log(this.props);
    this.setState({
      galleryThumbnailsIsOpen: !this.state.galleryThumbnailsIsOpen
    },
      // show selected thumb in carousel
      this.props.carouselProps.toggleModal(selectedIndex, true)
    );
  }

  render() {
    // console.log(this.props);
    const { currentIndex, currentView, views } = this.props;
    const galleryThumbsOpen = this.state.galleryThumbnailsIsOpen;
    const activeView = currentIndex + 1;
    const totalViews = views.length;
    const countText = !galleryThumbsOpen ? `${activeView} of ${totalViews}` : `1–${totalViews}`;
    const captionText = !galleryThumbsOpen ? `${currentView.caption}` : 'Full Gallery';
    let currentDownloadLink;
    if (this.props.views[currentIndex].download.link !== null) {
      currentDownloadLink = this.props.views[currentIndex].download.link;
    }

    // console.log(this.props);


    return (
      <React.Fragment>
        <footer className={`react-images__footer react-images__footer--isModal ${(!galleryThumbsOpen && currentDownloadLink) ? 'downloadable-option' : ''}`}>
          <button
            type="button"
            className={`gallery__btn-toggle-grid ${galleryThumbsOpen && 'gallery__btn-toggle-grid--isActive'}`}
            onClick={this.handleToggleThumbnails}
            title="Toggle gallery thumbnails view"><img src={btnToggleGrid} alt="Toggle gallery thumbnails view" /></button>
          <span className="react-images__footer__count react-images__footer__count--isModal">{countText}</span>
          <span className="react-images__footer__caption react-images__footer__caption--isModal">{captionText.replace(/Ó/, '').replace(/Ò/, '').replace(/Õ/, '').replace(/&amp;/, '&').replace(/Ô/, '').replace(/Õ/, "'").replace(/Ð/, '')}</span>
          {(!galleryThumbsOpen && currentDownloadLink) &&
            <a href={currentDownloadLink} className="gallery__btn-download">
              <img src="https://thehelm.polb.com/wp-content/plugins/wp-file-download/app/site/assets/images/theme/download.png" alt="Download" />
            </a>
          }
        </footer>

        <GalleryThumbnails
          thumbs={views}
          showThumbs={galleryThumbsOpen}
          handleThumbnailSelected={this.handleThumbnailSelected}
        />
      </React.Fragment>
    );
  }
}

export default GalleryCustomFooter;
